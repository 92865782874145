import {
	meta
} from 'lib/dom.js'

export const brand = meta('brand')
export const currency = meta('currency')
export const delimiter = meta('delimiter')
export const environment = meta('environment')
export const language = window.document.documentElement.lang
export const separator = meta('separator')
export const symbol = meta('symbol')
